import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { format } from 'utils/parseCost';

export default class InformationClave extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const { municipio, data: { CalculaPUResult: {
      Nombre_Propietario, RFC, Ubicacion_Propiedad, Domicilio_Propiedad, Colonia_Contri,
      Poblacion_Propiedad, Colonia_Propiedad,
      Valor_Catastral, Superficie_Terreno, Superficie_Construccion, Tipo_Subsidio
    } } } = this.props;

    return (
      <div className="auto-container">
        <div className="sec-title centered">
          <h2>Información de la Clave</h2>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="sidebar-widget quote-widget">
              <div className="widget-content">
                <div className="upper-box">
                  <div className="icon flaticon-user"></div>
                  <h3>{Nombre_Propietario}</h3>
                  <div className="touch">Nombre de Propietario</div>

                  <div className="touch">RFC {RFC}</div>
                  <div className="touch">Domicilio {Colonia_Contri}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-8 col-md-8 col-sm-8">
            <div className="sec-title centered">
              <div className="title">Predio</div>
            </div>
            <div className="row">
              <div className="col-6 col-md-6 col-sm-6">
                <ul className="list-style-four">
                  <li>Domicilio de Ubicación <strong><u>{Ubicacion_Propiedad}</u></strong></li>
                  <li>Domicilio <strong><u>{Domicilio_Propiedad}</u></strong></li>
                  <li>Colonia de Predio <strong><u>{Colonia_Propiedad}</u></strong></li>
                  <li>Población <strong><u>{Poblacion_Propiedad}</u></strong></li>
                  <li>Municipio <strong><u>{municipio}</u></strong></li>
                </ul>
              </div>
              <div className="col-6 col-md-6 col-sm-6">
                <ul className="list-style-four">
                  <li>Valor Catastral <strong><u>$ {format(Valor_Catastral, 2)}</u></strong></li>
                  <li>Superficie de Terreno <strong><u>{format(Superficie_Terreno, 2)}</u></strong></li>
                  <li>Superficie de Contrucción <strong><u>{format(Superficie_Construccion, 2)}</u></strong></li>
                  <li>Subsidio <strong><u>{Tipo_Subsidio}</u></strong></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

InformationClave.propTypes = {
  municipio: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

