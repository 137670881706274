import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class AlertDialog extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const { title, text } = this.props;

    return (
      <div className="auto-container">
        <div className="broucher-box">
          <div className="broucher-inner">
            <div className="content">
              <div className="icon flaticon-comment"></div>
              <h3>{title}</h3>
              <div className="file">{text}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

AlertDialog.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
};

AlertDialog.defaultProps = {
  title: '',
  text: ''
};
