import React, { Component } from 'react';
import HowPayOnline from './HowPayOnline';
import FindClave from './FindClave';
import ConsultaAdeudo from './ConsultaAdeudo';

export default class Instructions extends Component {

  constructor(props){
    super(props);
  }

  render() {
    return (
      <section className="services-section-three">
        <div className="auto-container">
          <div className="row">

            <div className="col-sm-4 col-md-4 mb-4 services-block-three">
              <HowPayOnline />
            </div>

            <div className="col-sm-4 col-md-4 mb-4 services-block-three">
              <FindClave />
            </div>

            <div className="col-sm-4 col-md-4 mb-4 services-block-three">
              <ConsultaAdeudo />
            </div>
          </div>
        </div>
      </section>
    );
  }

}
