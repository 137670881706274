import axios from 'axios';
import { API_KEY } from 'config/apiKey';

const dev = `http://localhost:2623/api/`;
const production = `https://pagoselota.com.mx/api/`;
export const BASE_URL = process.env.NODE_ENV === 'production' ? production : dev;
export const URL_FILES = BASE_URL.substring(0, BASE_URL.length - 4);

export const getRequest = (params = {}) => {
  if ( validateParameters(params) ) {
    axios
      .get(loadApi(params.url), {
        headers: {
          'User': localStorage.email,
          'Time': new Date()
        }
      })
      .then(response => params.success(response.data))
      .catch(error => {
        console.log(error);
        params.fail(error.response.statusText, error.response.status, error.response.data);
      });
  }
};

export const postRequest = (params = {}) => {
  if ( validateParameters(params) ) {
    axios
      .post(loadApi(params.url), params.body, {
        responseType: 'json',
        headers: {
          'User': localStorage.email,
          'Time': new Date()
        }
      })
      .then(response => params.success(response.data, response.status))
      .catch(error => {
        if ( error ) {
          if ( error.response ) {
            if ( error.response.status === 429 ) {
              window.location.href = '/';
            }
          }
        }

        if ( typeof error.response !== 'undefined' ) {
          params.fail(error.response.statusText, error.response.status, error.response.data);
        }else{
          params.fail();
        }
      });
  }
};

export const postUploadRequest = (params = {}) => {
  if ( validateParameters(params) ) {
    axios
      .post(loadApi(params.url), params.form, {
        responseType: 'json',
        headers: {
          'Content-Type': 'multipart/form-data',
          'User': localStorage.email,
          'Time': new Date()
        },
        onUploadProgress: params.onProgress()
      })
      .then(response => params.success(response.data, response.status))
      .catch(error => {
        if ( process.env.NODE_ENV !== 'production' ) {
          console.log(error);
        }
        params.fail(error.response.statusText, error.response.status, error.response.data);
      });
  }
};

export const putRequest = (params = {}) => {
  if ( validateParameters(params) ) {
    axios
      .put(loadApi(params.url), params.body, {
        responseType: 'json',
        headers: {
          'User': localStorage.email,
          'Time': new Date()
        }
      })
      .then(response => params.success(response.data, response.status))
      .catch(error => {
        console.log(error);
        params.fail(error.response.statusText, error.response.status, error.response.data);
      });
  }
};

export const deleteRequest = (params = {}) => {
  if ( validateParameters(params) ) {
    axios
      .delete(loadApi(params.url),{
        headers: {
          'User': localStorage.email,
          'Time': new Date()
        }
      })
      .then(response => params.success(response.data, response.status))
      .catch(error => {
        console.log(error);
        params.fail(error.response.statusText, error.response.status, error.response.data);
      });
  }
};

export const loadApi = url => {
  const segments = url.includes('?') ? `&` : `?`;

  return `${BASE_URL}${url}${segments}key=${API_KEY}`;
};

const validateParameters = (params) => {
  let successfully = true;

  if ( typeof(params.url) !== 'string' ) {
    successfully = false;
    throw 'url: undefined string';
  }

  if ( typeof(params.success) !== 'function' || typeof(params.fail) !== 'function' ) {
    successfully = false;
    throw 'undefined functions callback';
  }
  return successfully;
};

export const urlPhoto = () => {
  return BASE_URL.substring(0, BASE_URL.length - 4);
};

export const pathFilesApi = () => {
  return BASE_URL.substring(0, BASE_URL.length - 4);
};

export const imageExists = (imageUrl) => {
  var http = new XMLHttpRequest();

  http.open('HEAD', imageUrl, false);
  http.send();

  if ( http.status === 404 || http.status === 401 ) {
    return false;
  }
  return true;
};
