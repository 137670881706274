import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'stateless';
import { postRequest, URL_FILES } from 'utils/api';

export default class PreelaboradoPDF extends Component {

  constructor(props){
    super(props);
  }

  handlePDF() {
    const { predio, onClickCallback } = this.props;
    onClickCallback();

    postRequest({
      url: `preelaborado`,
      body: { predio },
      success: ({ file }) => {
        window.open(URL_FILES +'pdf/'+ file, '_blank');
      },
      fail: () => {}
    });
  }

  render() {
    return (
      <button onClick={this.handlePDF.bind(this)} className="btn btn-info pull-right" type="button">
        <Icon icon="file" /> Pagar en banco
      </button>
    );
  }

}

PreelaboradoPDF.propTypes = {
  predio: PropTypes.object.isRequired,
  onClickCallback: PropTypes.func.isRequired
};
