import React, { Component } from 'react';

export default class Header extends Component {

  constructor(props){
    super(props);
  }

  render() {
    return (
      <header className="main-header">
        <div className="header-top">
          <div className="auto-container clearfix text-center">
            <div className="value-text text-center"></div>
          </div>
        </div>

        <div className="header-upper">
          <div className="inner-container">
            <div className="auto-container clearfix">
              <div className="logo-outer">
                <div className="logo">
                  <a href="/"><img src="/img/ESCUDO.png" alt="" title="" style={{ marginTop:'10px', marginBottom:'10px', height:'90px' }} /></a>
                </div>
              </div>
              <div style={{ float: 'right' }}>
                <div className="logo">
                  <a href="/"><img src="/img/ELOTA.png" alt="" title="" style={{ marginTop:'5px', height:'100px' }} /></a>
                </div>
              </div>
              <div className="text-center" style={{ paddingTop:'26px' }}>
                <h1 className="d-none d-sm-block">H. Ayuntamiento de Elota 2021 - 2024</h1>
                <h5 className="d-block d-sm-none">H. Ayuntamiento de Elota</h5>
                <h5 className="d-block d-sm-none">2021 - 2024</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="sticky-header">
          <div className="auto-container clearfix">
            <div className="logo pull-left">
              <a href="/"><img src="/img/ESCUDO.png" alt="" title="" style={{ marginTop:'10px', marginBottom:'10px', height:'90px' }} /></a>
            </div>
            <div className="logo-outer" style={{ float: 'right' }}>
              <div className="logo">
                <a href="/"><img src="/img/ELOTA.png" alt="" title="" style={{ marginTop:'5px', height:'100px' }} /></a>
              </div>
            </div>
            <div className="text-center" style={{ paddingTop:'26px' }}>
              <h1 className="d-none d-sm-block">H. Ayuntamiento de Elota 2021 - 2024</h1>
              <h5 className="d-block d-sm-none">H. Ayuntamiento de Elota</h5>
              <h5 className="d-block d-sm-none">2021 - 2024</h5>
            </div>
          </div>
        </div>
      </header>
    );
  }

}
