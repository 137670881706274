import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon } from 'stateless';
import { TableRowsItems, ItemLabelTable, LoadingSpin } from 'components/utils';
import IconBrand from './IconBrand';
import { format } from 'utils/parseCost';
import { postRequest } from 'utils/api';
import { APROBADA, DECLINADA, RECHAZADA, SIN_RESPUESTA } from 'config/constants';

export default class Autorizacion extends Component {

  constructor(props){
    super(props);
    this.state = {
      loading: true,
      data: null,
      clave: '',
      fail: false,
      error: {
        visible: false,
        title: '',
        message: ''
      },
      rechazada: false,
      declinada: false,
      authorizing: false
    };
  }

  componentDidMount(){
    this.loadData();
  }

  loadData(){
    const { location } = this.props;
    const params = new URLSearchParams(location.search);

    if ( params.get('h') === '0' ) {
      this.setState({
        fail: true,
        clave: params.get('clave'),
        loading: false
      });
    }else{
      postRequest({
        url: `auth`,
        body: {
          hash: params.get('h')
        },
        success: ({ data }) => {
          this.setState({
            data,
            loading: false
          });
        },
        fail: (error, status, data) => {
          console.log(error, status, data);
          window.location.href = '/';
        }
      });
    }
  }

  renderView() {
    const { data: { Clave, Customer, Name, CardNumber, CardType, Expires, Total }, error, authorizing, rechazada, declinada } = this.state;

    return (
      <div className="consult-section">
        <div className="auto-container">

          <Link to={`/predio?clave=${Clave}`}>Regresar</Link>

          <div className="sec-title-2 light centered">
            <div className="title">PREDIAL URBANO</div>
            <h2>AUTORIZACIÓN DE PAGO</h2>
          </div>

          <section className="cart-section" style={{paddingTop: '20px', marginTop: '50px'}}>
            <div className="cart-outer">
              <TableRowsItems>
                <ItemLabelTable label="Nombre" value={Customer} />
                <ItemLabelTable label="Impuesto" value={`$ ${format(Total, 2)}`} />
              </TableRowsItems>

              <TableRowsItems>
                <ItemLabelTable label="Nombre del tarjetahabiente" value={Name} />
                <ItemLabelTable label="Tarjeta" value={<IconBrand brand={CardType} />} />
                <ItemLabelTable label="Número de tarjeta" value={CardNumber} />
                <ItemLabelTable label="Fecha de expiración" value={Expires} />
                <ItemLabelTable label="Impuesto" value={`$ ${format(Total, 2)}`} />
                <ItemLabelTable label="Comisión" value={`$ ${format(0, 2)}`} />
                <ItemLabelTable label="Total a pagar" value={`$ ${format(Total, 2)}`} />
              </TableRowsItems>

              <p>* Favor de verificar los datos y oprimir &quot;Autorizo pago&quot; para proceder a realizar el cargo a su tarjeta.</p>
            </div>
          </section>

          <div className="consult-form">
            <form className="well">
              <div className="row">
                <div className="form-group col-lg-4 col-md-4 col-sm-4"></div>
                <div className="form-group col-lg-4 col-md-4 col-sm-4">
                  <button onClick={this.handleBanorte.bind(this)} disabled={authorizing} className="theme-btn btn-style-one text-center centered" type="button"><Icon icon="check-square" /> <i>Autorizo pago</i></button>
                </div>
                <div className="form-group col-lg-4 col-md-4 col-sm-4"></div>
              </div>
            </form>

            { authorizing ? <LoadingSpin /> : null }

            {
              error.visible ?
                <div className="auto-container">
                  <div className="broucher-box">
                    <div className="broucher-inner" style={{background: '#d9534f', color: 'white'}}>
                      <div className="content">
                        <div className="icon">
                          <Icon icon="exclamation-circle" />
                        </div>
                        <h3>{error.title}</h3>
                        <div className="file" style={{color: 'white'}}>{error.message}</div>
                      </div>
                    </div>
                  </div>
                </div> : null
            }

            { declinada || rechazada  ? this.renderRechazaDeclinada() : null }

          </div>
        </div>
      </div>
    );
  }

  handleBanorte(event) {
    event.preventDefault();
    this.setState({
      authorizing: true,
      rechazada: false,
      declinada: false
    });

    const { location } = this.props;
    const params = new URLSearchParams(location.search);

    postRequest({
      url: `bank`,
      body: {
        hash: params.get('h')
      },
      success: ({ data }, status) => {
        this.setState({ authorizing: false });

        if ( status === APROBADA ) {
          const { history } = this.props;

          setTimeout(() => {
            history.replace(`/recibo?i=${data.code}`);
          }, 100);

        }else if ( status === RECHAZADA ) {
          this.setState({ rechazada: true });
        }else if ( status === DECLINADA ) {
          this.setState({ declinada: true });
        }else if ( status === SIN_RESPUESTA ) {
          this.setState({
            error: {
              visible: true,
              title: 'Aviso',
              message: 'Su movimiento no puede ser realizado, porque se conto con respuesta del autorizador'
            }
          });
        }
      },
      fail: (error, status, data) => {
        console.log(error, status, data);
        this.setState({ authorizing: false });
      }
    });
  }

  renderMessage() {
    const { clave } = this.state;

    return (
      <div className="consult-section">
        <div className="auto-container">
          <Link to={`/predio?clave=${clave}`}>Regresar</Link>
          <br />
          <br />

          <div className="auto-container">
            <div className="broucher-box">
              <div className="broucher-inner" style={{background: '#d9534f', color: 'white'}}>
                <div className="content">
                  <div className="icon">
                    <Icon icon="exclamation-triangle" />
                  </div>
                  <h3>Aviso</h3>
                  <div className="file" style={{color: 'white'}}>Hubos problemas con su tarjeta, favor de verificar los datos proporcionados.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRechazaDeclinada() {
    return (
      <div className="auto-container">
        <div className="broucher-box">
          <div className="broucher-inner" style={{background: '#d9534f', color: 'white'}}>
            <div className="content">
              <div className="icon">
                <Icon icon="exclamation-circle" />
              </div>
              <h3>Ocurrió un error al intentar procesar el pago</h3>
              <div className="file" style={{color: 'white'}}>
                No fue posible verificar los datos de su tarjeta. Le sugerimos que verifique los datos de su tarjeta sean correctos y que cuente con fondos suficientes para la transacción. Revíselos e intente nuevamente.
                <br />
                <br />
                Si el problema persiste le recomendamos que contacte a su banco emisor.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { loading, data, fail } = this.state;

    return (
      <div>
        { loading ? <LoadingSpin /> : null }

        { data !== null ? this.renderView() : null }

        {  fail ? this.renderMessage() : null }
      </div>
    );
  }

}

Autorizacion.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};
