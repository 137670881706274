import React, { Component } from 'react';
import ReactLoading from 'react-loading';

export default class LoadingSpin extends Component {

  constructor(props){
    super(props);
  }

  render() {
    return (
      <div className="auto-container">
        <div className="cart-outer">
          <div className="row clearfix">
            <div className="column col-5 col-lg-5 col-md-5 col-sm-5">
            </div>
            <div className="column col-2 col-lg-2 col-md-2 col-sm-2 text-center">
              <ReactLoading type="spin" color="#00913d" height={100} width={50} className="text-center" />
            </div>
            <div className="column col-5 col-lg-5 col-md-5 col-sm-5">
            </div>
          </div>
        </div>
      </div>
    );
  }

}
