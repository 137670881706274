import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { format } from 'utils/parseCost';

export default class DetailImports extends Component {

  constructor(props){
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const { name, checked } = event.target;

    const element = name.split('-');

    this.props.onChangePeriods({
      item: parseInt(element[1]),
      anio: element[2],
      trimestre: element[3],
      checked
    });
  }

  render() {
    const { listChecks, data: { CalculaPUResult: { Detalle: { Detalle } } } } = this.props;

    return (
      <div className="auto-container">
        <div className="cart-outer">
          <div className="table-outer">
            <table className="cart-table">
              <thead className="cart-header">
                <tr>
                  <th scope="col">Período</th>
                  <th scope="col">Principal</th>
                  <th scope="col">A.C.O.</th>
                  <th scope="col">Asistencia social</th>
                  <th scope="col">Subsidio</th>
                  <th scope="col">Pronto pago</th>
                  <th scope="col">Recargo</th>
                  <th scope="col">Multa</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>
                {
                  Detalle.map((d, i) => {
                    const { anio, trimestre, principal, actualizacion, asistencia_social, descuento, prontopago, recargos, multas, total } = d;

                    const item = listChecks.find(c => c.anio === anio && c.trimestre === trimestre);
                    const elementPeriod = `period-${i}-${item.anio}-${item.trimestre}`;

                    return (
                      <tr key={i}>
                        <th scope="row">
                          <div className="col-auto my-1">
                            <div className="custom-control custom-checkbox mr-sm-2">
                              <input id={elementPeriod} name={elementPeriod} className="custom-control-input"
                                type="checkbox" checked={item.checked} onChange={this.onChange} />
                              <label className="custom-control-label" htmlFor={elementPeriod}>{anio} - {trimestre}</label>
                            </div>
                          </div>
                        </th>
                        <td>$ {format(principal, 2)}</td>
                        <td>$ {format(actualizacion, 2)}</td>
                        <td>$ {format(asistencia_social, 2)}</td>
                        <td>$ {format(descuento, 2)}</td>
                        <td>$ {format(prontopago, 2)}</td>
                        <td>$ {format(recargos, 2)}</td>
                        <td>$ {format(multas, 2)}</td>
                        <td>$ {format(total, 2)}</td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

}

DetailImports.propTypes = {
  listChecks: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  onChangePeriods: PropTypes.func
};
