import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class PrivacyNotice extends Component {

  constructor(props){
    super(props);
  }

  render() {
    return (
      <section className="faq-banner-section">
        <Link to="/" className="btn btn-style-one"><i>Volver </i> <span className="fa fa-home"></span></Link>
        <div className="">
          <div className="auto-container">
            <div className="sec-title-2 light centered">
              <h2>Aviso de Privacidad</h2>
            </div>
            <div className="text">
              <p>Welcomed and every pain avoided. But in certain circumstances and owing to the claims of duty or bligations of business it will frequently occur that pleasures have to be repudiated & annoyances that accepted. The wise man therefore always holds in these matters to this principle occur in which toil  him some great to take a trivial example, which of us ever undertakes.</p>
              <p>Owing to the claims of duty or bligations of business it will frequently occur that pleasures have to be repudiated & annoyances that accepted. The wise man therefore always holds in these matters to this  in which toil  him some great to take a trivial example.</p>
            </div>
          </div>
        </div>
      </section>

    );
  }

}
