import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ErrorBoundary from 'components/ErrorBoundary';

import { Dashboard } from 'containers/';
import { PageNotFound } from 'components/application/security';
import { Consulta, Autorizacion, Recibo } from 'components/predial';
import { Header, Footer, PrivacyNotice } from 'components/utils';

export default class App extends Component {

  constructor(props){
    super(props);
  }

  renderMenu(){
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Dashboard} />

          <Route path="/predio" component={Consulta} />

          <Route path="/autorizacion" component={Autorizacion} />
          <Route path="/recibo" component={Recibo} />

          <Route path="/aviso-privacidad" component={PrivacyNotice} />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    );
  }

  render() {
    return (
      <Router>
        <ErrorBoundary>
          <Header />
          { this.renderMenu() }
          <Footer />
        </ErrorBoundary>
      </Router>
    );
  }

}
