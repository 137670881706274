import React, { Component } from 'react';
import { Modal, Header, Title, Body } from 'react-bootstrap';

export default class ConsultaAdeudo extends Component {

  constructor(props){
    super(props);
    this.state = {
      showModal: false
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal () {
    this.setState({ showModal: true });
  }

  handleCloseModal () {
    this.setState({ showModal: false });
  }

  render() {
    const { showModal } = this.state;

    return (
      <div>
        <div className="inner-box text-center">
          <div className="icon-box">
            <button onClick={this.handleOpenModal.bind(this)} type="button"><img src="./img/iconoclavecatastral.png" alt="" title="" style={{width:'120px'}} /></button>
          </div>
          <div className="text text-center">
            Consulta tu adeudo
          </div>
        </div>

        <Modal bsSize="large" animation={false} show={showModal} onHide={this.handleCloseModal}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <img src="./img/consultatuadeudo.jpg" alt="" title="" width="100%" />
          </Modal.Body>
        </Modal>
      </div>
    );
  }

}
