import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class TableRowsItems extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const { children } = this.props;

    return (
      <ul className="totals-table">
        {children}
      </ul>
    );
  }

}

TableRowsItems.propTypes = {
  children: PropTypes.node
};
