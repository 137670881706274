
/**
 * Plataformas permitidas
 */
const ANDROID = 'ANDROID';
const IOS     = 'IOS';
const BACKEND = 'BACKEND';
const SPECIAL = 'SPECIAL';
const URL     = 'URL';

export const platforms = {
  ANDROID: ANDROID,
  IOS: IOS,
  BACKEND: BACKEND,
  SPECIAL: SPECIAL,
  URL: URL
};

/**
 * https://momentjs.com/docs/#/manipulating/add/
 */
export const TYPE_RANKS = {
  MONTH:  'months',
  WEEK:   'weeks',
  DAY:    'days',
  HOUR:   'hours',
  MINUTE: 'minutes'
};

export const SESSION_EXPIRATION = {
  TIME: 2,
  RANK: TYPE_RANKS.HOUR
};

/**
 * Entornos
 */
export const PRODUCTION = 'PRODUCTION';
export const DEVELOPMENT = 'DEVELOPMENT';

export const TYPES_USERS = {
  SUPERADMIN:  'SUPERADMIN',
  NORMAL: 'NORMAL'
};

export const TYPES_USERS_FRONT = {
  ADMIN:  'ADMIN',
  MEMBER: 'SOCIO'
};

export const MC = 'mastercard';
export const VISA = 'visa';

export const APROBADA = 201;
export const DECLINADA = 202;
export const RECHAZADA = 203;
export const SIN_RESPUESTA = 204;
