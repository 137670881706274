import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ItemConcepImports from './ItemConcepImports';

export default class SummaryImports extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const { ImporteCruzRoja, Diferencia_predial, Honorarios, Honorarios_Notificacion, CantidadFijaGC, Gastos_ejec,
      GastosEjecRequerimiento, GastosEjecEmbargo, GastosExtCobranza, Redondeo, Total } = this.props;

    return (
      <div className="auto-container">
        <div className="cart-outer">
          <div className="row clearfix">
            <div className="column col-lg-6 col-md-12 col-sm-12">
            </div>
            <div className="column col-lg-6 col-md-12 col-sm-12">
              <ul className="totals-table">
                <ItemConcepImports label="Cruz roja" amount={ImporteCruzRoja} />
                <ItemConcepImports label="Diferencia de Predial" amount={Diferencia_predial} />
                <ItemConcepImports label="Honorarios" amount={Honorarios} />
                <ItemConcepImports label="Honorarios de Notificación" amount={Honorarios_Notificacion} />
                <ItemConcepImports label="Gastos de Cobranza" amount={CantidadFijaGC} />
                <ItemConcepImports label="Gastos de Ejecución" amount={Gastos_ejec} />
                <ItemConcepImports label="Gastos de Ejecución de Requerimiento" amount={GastosEjecRequerimiento} />
                <ItemConcepImports label="Gastos de Ejecución de Embargo" amount={GastosEjecEmbargo} />
                <ItemConcepImports label="Gastos Extraordinarios de Cobranza" amount={GastosExtCobranza} />
                <ItemConcepImports label="Redondeo" amount={Redondeo} />
                <ItemConcepImports label="Total" amount={Total} />
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

SummaryImports.propTypes = {
  ImporteCruzRoja: PropTypes.string.isRequired,
  Diferencia_predial: PropTypes.string.isRequired,
  Honorarios: PropTypes.string.isRequired,
  Honorarios_Notificacion: PropTypes.string.isRequired,
  CantidadFijaGC: PropTypes.string.isRequired,
  Gastos_ejec: PropTypes.string.isRequired,
  GastosEjecRequerimiento: PropTypes.string.isRequired,
  GastosEjecEmbargo: PropTypes.string.isRequired,
  GastosExtCobranza: PropTypes.string.isRequired,
  Redondeo: PropTypes.string.isRequired,
  Total: PropTypes.string.isRequired
};
