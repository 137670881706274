import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'stateless';
import { postRequest, URL_FILES } from 'utils/api';

export default class PDFRecibo extends Component {

  constructor(props){
    super(props);
  }

  handlePDF() {
    const { id } = this.props;

    postRequest({
      url: `print`,
      body: { id },
      success: ({ file }) => {
        window.open(URL_FILES +'pdf/'+ file, '_blank');
      },
      fail: () => {}
    });
  }

  render() {
    return (
      <button onClick={this.handlePDF.bind(this)} className="btn btn-success pull-right" type="button">
        <Icon icon="print" /> Imprimir Recibo
      </button>
    );
  }

}

PDFRecibo.propTypes = {
  id: PropTypes.string.isRequired
};
