import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-credit-cards';
import { Icon } from 'stateless';
import { BANORTE } from 'config/banorte';
import { MC, VISA } from 'config/constants';
import { postRequest } from 'utils/api';
import { AlertDialog } from 'components/utils';
import { isEmpty } from 'validator';

export default class CreditCard extends Component {

  constructor(props){
    super(props);
    this.state = {
      success: false,
      Reference3D: '',
      error: {
        visible: false,
        title: '',
        message: ''
      },
      redirectBanorte: false
    };
  }

  validateCard() {
    const { predio, Name, number, Expires, cvc, issuer, Total,
      data: { CalculaPUResult: { Nombre_Propietario, Cve_Catastral } } } = this.props;

    const { CalculaPUResult: { Hasta } } = predio;

    const body = {
      Cve_Catastral, Nombre_Propietario, Hasta, Name, number, Expires, cvc, issuer, Total, predio
    };

    if ( this.validations(body) ) {
      this.setState({ redirectBanorte: true });

      postRequest({
        url: `secure3d`,
        body,
        success: ({ data }, status) => {
          if ( status === 201 ) {
            this.setState({
              success: true,
              Reference3D: data
            });

            this.btn.click();
          }else{
            this.setState({ redirectBanorte: false });
          }
        },
        fail: (error, status, data) => {
          console.log(error, status, data);
          this.setState({ redirectBanorte: false });
        }
      });
    }
  }

  render() {
    const { Reference3D, error, redirectBanorte } = this.state;

    const { Name, number, Expires, cvc, focused, issuer, paymentLoading, Total,
      handleCallback, handleInputChange, handleInputFocus, recalculate } = this.props;

    const NumberCard = number.replace(/ /g,'');

    let CardType = '';
    if ( issuer === MC ) {
      CardType = 'MC';
    }else if ( issuer === VISA ) {
      CardType = 'VISA';
    }

    return (
      <div className="row">
        <legend>Información de pago</legend>
        <div className="col-lg-6 col-md-6 col-sm-6">
          <Card
            number={number} name={Name} expiry={Expires}
            cvc={cvc} focused={focused} callback={handleCallback}
            acceptedCards={['visa', 'mastercard']}
            placeholders={{ name: 'NOMBRE' }}
            locale={{ valid: 'Expiración' }} />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div className="form-group">
            <input type="tel" name="number" className="form-control"
              placeholder="Número de tarjeta" pattern="[\d| ]{16,22}" required
              onChange={handleInputChange} onFocus={handleInputFocus} />
            <small>ej.: 49..., 51..., 36..., 37...</small>
          </div>
          <div className="form-group">
            <input type="text" name="Name" className="form-control"
              placeholder="Nombre del tarjetahabiente" required
              onChange={handleInputChange} onFocus={handleInputFocus} />
          </div>
          <div className="row">
            <div className="col-6">
              <input type="tel" name="Expires" className="form-control"
                placeholder="Fecha de expiración (MM/AA)" pattern="\d\d/\d\d" required
                onChange={handleInputChange} onFocus={handleInputFocus} />
            </div>
            <div className="col-6">
              <input type="tel" name="cvc" className="form-control"
                placeholder="CVC" pattern="\d{3,4}" required
                onChange={handleInputChange} onFocus={handleInputFocus} />
            </div>
          </div>
          <input type="hidden" name="issuer" value={issuer} />

          <br />
          <div className="form-actions">
            {
              paymentLoading ?
                <div className="progress" style={{ marginTop:'30px', marginBottom:'30px' }}>
                  <div className="progress-bar progress-bar-success progress-bar-striped active" role="progressbar"
                    aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width:'100%', backgroundColor:'#5cb85c' }}>
                    <span>Tu pago esta procesandose, favor de esperar</span>
                  </div>
                </div>
                :
                <button type="button" onClick={this.validateCard.bind(this)} className="theme-btn btn-style-one text-center centered disabled"
                  disabled={((paymentLoading || recalculate) && ! redirectBanorte) || redirectBanorte}><Icon icon="credit-card" /> <i>Pagar</i></button>
            }
          </div>

          <br />
          {
            error.visible ? <AlertDialog title={error.title} text={error.message} /> : null
          }

          <form action={BANORTE.URL} method="POST">
            <input type="hidden" name="Card" value={NumberCard} />
            <input type="hidden" name="Expires" value={Expires} />
            <input type="hidden" name="Total" value={Total} />
            <input type="hidden" name="CardType" value={CardType} />
            <input type="hidden" name="MerchantId" value={BANORTE.MERCHANT_ID} />
            <input type="hidden" name="MerchantName" value={BANORTE.NOMBRE} />
            <input type="hidden" name="MerchantCity" value={BANORTE.CITY} />
            <input type="hidden" name="ForwardPath" value={BANORTE.FORWARD_PATH} />
            <input type="hidden" name="Cert3D" value={BANORTE.CERT_3D} />
            <input type="hidden" name="Reference3D" value={Reference3D} />
            <button ref={(r) => { this.btn = r; }} type="submit"></button>
          </form>
        </div>
      </div>
    );
  }

  validations(params) {
    let success = true;
    let message = '';

    if ( isEmpty(params.Cve_Catastral + '') ) {
      success = false;
      message = 'Falta información requerida.';
    }

    if ( success && isEmpty(params.Nombre_Propietario + '') ) {
      success = false;
      message = 'Falta información requerida.';
    }

    if ( success && isEmpty(params.Hasta + '') ) {
      success = false;
      message = 'Falta información requerida.';
    }

    if ( success && isEmpty(params.Total + '') ) {
      success = false;
      message = 'Falta información requerida.';
    }

    if ( success && isEmpty(params.number + '') ) {
      success = false;
      message = 'Número de tarjeta es necesario.';
    }

    if ( success && isEmpty(params.issuer + '') ) {
      success = false;
      message = 'Tarjeta inválida.';
    }

    if ( success && isEmpty(params.Name + '') ) {
      success = false;
      message = 'Nombre del tarjetahabiente es necesario.';
    }

    if ( success && isEmpty(params.Expires + '') ) {
      success = false;
      message = 'Fecha de expiración es necesario.';
    }

    if ( success && isEmpty(params.cvc + '') ) {
      success = false;
      message = 'CVC es necesario.';
    }

    if ( ! success ) {
      this.setState({
        error: {
          visible: true,
          title: 'Información incompleta',
          message: message
        }
      });
    }

    return success;
  }

}

CreditCard.propTypes = {
  clave: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  predio: PropTypes.object.isRequired,
  Name: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  Expires: PropTypes.string.isRequired,
  cvc: PropTypes.string.isRequired,
  focused: PropTypes.string.isRequired,
  issuer: PropTypes.string.isRequired,
  Total: PropTypes.string.isRequired,
  paymentLoading: PropTypes.bool.isRequired,
  recalculate: PropTypes.bool.isRequired,
  handleCallback: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleInputFocus: PropTypes.func.isRequired
};

