import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { isEmpty } from 'validator';
import { LoadingProgress, AlertDialog, AlertPaid, Instructions } from 'components/utils';

const maskComplete = createNumberMask({ allowDecimal: false, prefix: '', allowLeadingZeroes: true });

// pagada 022-000-001-001-002-001
// normal 022-000-002-244-006-001

class Admin extends Component {

  constructor(props){
    super(props);
    this.state = {
      formClass: 'needs-validation',
      formCompleted: true,
      serviceNotFound: false,
      loadingProgress: false,
      mun: '022',
      pob: '',
      cua: '',
      man: '',
      col: '',
      uni: '',
      data: null,
      ImporteCruzRoja: '',
      Diferencia_predial: '',
      Honorarios: '',
      Honorarios_Notificacion: '',
      CantidadFijaGC: '',
      Gastos_ejec: '',
      GastosEjecRequerimiento: '',
      GastosEjecEmbargo: '',
      GastosExtCobranza: '',
      Redondeo: '',
      Total: '',
      municipio: '',
      listChecks: [],
      recalculate: false,
      isPayment: false,
      number: '',
      Name: '',
      Expires: '',
      cvc: '',
      issuer: '',
      focused: '',
      paymentLoading: false,
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const { name, value } = event.target;
    const data = parseInt(value);

    this.setState({ [name]: value });

    if ( ! isNaN(data) && value.length === 3 ) {
      if ( name === 'mun' ) {
        this.frm['pob'].focus();

      }else if ( name === 'pob' ) {
        this.frm['cua'].focus();

      }else if ( name === 'cua' ) {
        this.frm['man'].focus();

      }else if ( name === 'man' ) {
        this.frm['col'].focus();

      }else if ( name === 'col' ) {
        this.frm['uni'].focus();
      }
    }
  }

  handleSearch(event) {
    event.preventDefault();
    this.setState({
      data: null,
      formClass: 'needs-validation',
      formCompleted: true,
      loadingProgress: true,
      serviceNotFound: false
    });

    const { history } = this.props;
    const { mun, pob, cua, man, col, uni } = this.state;

    const body = {
      clave: `${mun}-${pob}-${cua}-${man}-${col}-${uni}`
    };

    if ( this.validateClave(body) ) {
      history.replace(`/predio?clave=${mun}${pob}${cua}${man}${col}${uni}`);
    }
  }

  renderClavePay() {
    return (
      <section className="cart-section">
        <AlertPaid type="danger" title="Aviso" text="La Clave Catastral es incorrecta" />
      </section>
    );
  }

  renderForm() {
    const { loadingProgress, formCompleted, serviceNotFound, mun, pob, cua, man, col, uni } = this.state;

    return (
      <section className="consult-section" style={{ background: '#d8d8d8' }}>
        <div className="auto-container">

          <div className="sec-title-2 light centered">
            <div className="title">PREDIAL URBANO</div>
            <h2>CONSULTA TU CLAVE CATASTRAL</h2>
            <div className="text">&quot;Ahorra tiempo, evita desplazamientos&quot;</div>
          </div>

          <div className="consult-form">
            <form ref={(form) => { this.frm = form; }}>
              <div className="row clearfix">

                <div className="form-group col-lg-2 col-md-2 col-sm-4 col-4">
                  <MaskedInput type="text" tabIndex="1" id="mun" name="mun" placeholder="MUN"
                    required disabled mask={maskComplete} maxLength={3} autoComplete="off"
                    value={mun} />
                </div>

                <div className="form-group col-lg-2 col-md-2 col-sm-4 col-4">
                  <MaskedInput type="text" tabIndex="2" id="pob" name="pob" placeholder="POB"
                    required mask={maskComplete} maxLength={3} autoComplete="off"
                    defaultValue={pob} onChange={this.onChange} />
                </div>

                <div className="form-group col-lg-2 col-md-2 col-sm-4 col-4">
                  <MaskedInput type="text" tabIndex="3" id="cua" name="cua" placeholder="CUA"
                    required mask={maskComplete} maxLength={3} autoComplete="off"
                    defaultValue={cua} onChange={this.onChange} />
                </div>

                <div className="form-group col-lg-2 col-md-2 col-sm-4 col-4">
                  <MaskedInput type="text" tabIndex="4" id="man" name="man" placeholder="MAN"
                    required mask={maskComplete} maxLength={3} autoComplete="off"
                    defaultValue={man} onChange={this.onChange} />
                </div>

                <div className="form-group col-lg-2 col-md-2 col-sm-4 col-4">
                  <MaskedInput type="text" tabIndex="5" id="col" name="col" placeholder="COL"
                    required mask={maskComplete} maxLength={3} autoComplete="off"
                    defaultValue={col} onChange={this.onChange} />
                </div>

                <div className="form-group col-lg-2 col-md-2 col-sm-4 col-4">
                  <MaskedInput type="text" tabIndex="6" id="uni" name="uni" placeholder="UNI"
                    required mask={maskComplete} maxLength={3} autoComplete="off"
                    defaultValue={uni} onChange={this.onChange} />
                </div>

                <div className="form-group col-lg-4 col-md-4 col-sm-4"></div>
                <div className="form-group col-lg-4 col-md-4 col-sm-4">
                  <button onClick={this.handleSearch.bind(this)} className="theme-btn btn-style-two text-center centered" type="button"><i>Consultar</i> <span className="fa fa-search"></span></button>
                </div>
                <div className="form-group col-lg-4 col-md-4 col-sm-4"></div>
              </div>
            </form>
          </div>
        </div>

        { ! formCompleted ? <AlertDialog type="danger" title="Aviso" text="La Clave Catastral es incorrecta" /> : null }

        { serviceNotFound ? <AlertDialog title="Aviso" text="El servicio no se encuentra disponible por el momento" /> : null }

        { loadingProgress ? <LoadingProgress /> : null }
      </section>
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderForm()}
        <Instructions />
      </Fragment>
    );
  }

  validateClave(data) {
    let complete = true;

    const { clave } = data;

    if ( isEmpty(clave  + '') ) {
      complete = false;

      this.setState({
        data: null,
        formClass: 'needs-validation was-validated',
        loadingProgress: false
      });
    }

    const clave1 = clave.replace(/_/g, '');
    const clave2 = clave1.split('-');

    const fieldInvalid = clave2.reduce((t, f) => {
      if ( isNaN(parseInt(f)) ) {
        t++;
      }
      return t;
    }, 0);

    if ( complete  && ( clave2.length !== 6 || fieldInvalid > 0 ) )  {
      complete = false;

      this.setState({
        data: null,
        formClass: 'needs-validation was-validated',
        formCompleted: false,
        loadingProgress: false
      });
    }

    return complete;
  }

}

Admin.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(Admin);
