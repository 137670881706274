const KEY_DEV = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwbGF0Zm9ybSI6IlNQRUNJQUwiLCJlbnZpcm9ubWVudCI6IkRFVkVMT1BNRU5UIiwiY3VycmVudCI6IjIwMTktMDYtMjJUMTg6Mjk6NDguOTgxWiJ9.RZrsA6cJohCw5XhYr-oVZ_SxIiYeXYhP7igYravZdTM';
const KEY_PRO = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwbGF0Zm9ybSI6IlNQRUNJQUwiLCJlbnZpcm9ubWVudCI6IlBST0RVQ1RJT04iLCJjdXJyZW50IjoiMjAxOS0wNy0yNFQyMDo0Nzo1Mi44NTVaIn0.-Rt0wYDtnfWv15poVtlmHusOIMVYBlKmJHC8HtSrAd0';

const KEY = process.env.NODE_ENV === 'production' ? KEY_PRO : KEY_DEV;

const URL_DEV = 'http://localhost:2623';
const URL_PRO = 'https://pagoselota.com.mx';

const URL = process.env.NODE_ENV === 'production' ? URL_PRO : URL_DEV;

const URL_CONFIRM_PAYWORKS = URL + '/api/banorte?key=' + KEY;

const Solucion3DSecure = 'https://eps.banorte.com/secure3d/Solucion3DSecure.htm';

export const BANORTE = {
  URL: Solucion3DSecure,
  MERCHANT_ID: 8224656,
  NOMBRE: 'MUNICIPIO DE ELOTA',
  CITY: 'Elota, Sinaloa',
  FORWARD_PATH: URL_CONFIRM_PAYWORKS,
  CERT_3D: '03'
};
