import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { postRequest } from 'utils/api';
import { isEmpty, isHash } from 'validator';
import { format } from 'utils/parseCost';
import { TableRowsItems, ItemLabelTable, LoadingSpin } from 'components/utils';
import PDFRecibo from './PDFRecibo';

export default class Recibo extends Component {

  constructor(props){
    super(props);
    this.state = {
      loading: true,
      data: null
    };
  }

  componentDidMount(){
    this.loadData();
  }

  loadData(){
    const { location } = this.props;
    const params = new URLSearchParams(location.search);

    if ( ! isEmpty( params.get('i') + '') && isHash( params.get('i'), 'sha1') ) {
      postRequest({
        url: `orden`,
        body: {
          code: params.get('i')
        },
        success: ({ data }) => {
          this.setState({
            data,
            loading: false
          });
        },
        fail: (error, status, data) => {
          console.log(error, status, data);
          window.location.href = '/';
        }
      });
    }else{
      window.location.href = '/';
    }
  }

  renderView() {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const { data: { codigo_aut, referencia, total, propietario, clave, periodo } } = this.state;

    const mun = clave.substring(0, 3);
    const pob = clave.substring(3, 6);
    const cua = clave.substring(6, 9);
    const man = clave.substring(9, 12);
    const col = clave.substring(12, 15);
    const uni = clave.substring(15, 18);

    const claveCatastral = `${mun}-${pob}-${cua}-${man}-${col}-${uni}`;

    return (
      <div>
        <div className="consult-section">
          <div className="auto-container">
            <div className="sec-title-2 light centered">
              <h2>CONFIRMACIÓN DE PAGO EXITOSO</h2>
            </div>

            <PDFRecibo id={params.get('i')} />

            <section className="cart-section" style={{paddingTop: '50px', marginTop: '80px'}}>
              <div className="cart-outer">
                <TableRowsItems>
                  <ItemLabelTable label="Número de Autorización" value={codigo_aut} />
                  <ItemLabelTable label="Referencia" value={referencia} />
                  <ItemLabelTable label="Propietario" value={propietario} />
                  <ItemLabelTable label="Clave Catastral" value={claveCatastral} />
                  <ItemLabelTable label="Período pagado" value={periodo} />
                  <ItemLabelTable label="Total" value={`$ ${format(total, 2)}`} />
                </TableRowsItems>
              </div>
            </section>

            <div className="text-center">
              <Link to="/" className="btn btn-style-one"><i>INICIO </i> <span className="fa fa-home"></span></Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { loading, data } = this.state;

    return (
      <div>
        { loading ? <LoadingSpin /> : null }

        { ! loading && data !== null ? this.renderView() : null }
      </div>
    );
  }

}

Recibo.propTypes = {
  location: PropTypes.object.isRequired
};
