import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { postRequest } from 'utils/api';
import { Icon } from 'stateless';
import { isEmpty } from 'validator';
import { LoadingProgress, AlertPaid, InformationClave, DetailImports,
  SummaryImports, CreditCard, AlertDialog, LoadingSpin } from 'components/utils';
import { formatCreditCardNumber, formatCVC, formatExpirationDate } from 'utils/payment';
import 'react-credit-cards/es/styles-compiled.css';
import PreelaboradoPDF from './PreelaboradoPDF';

const now = new Date();
const IS_PAY = `${now.getFullYear()}4`;

const OPCION_PAGO_LINEA = 1;
const OPCION_PREELABORADO = 2;

export default class ConsultaPredio extends Component {

  constructor(props){
    super(props);
    this.state = {
      formClass: 'needs-validation',
      formCompleted: true,
      serviceNotFound: false,
      loadingProgress: true,
      mun: '022',
      pob: '',
      cua: '',
      man: '',
      col: '',
      uni: '',
      data: null,
      predio: null,
      ImporteCruzRoja: '',
      Diferencia_predial: '',
      Honorarios: '',
      Honorarios_Notificacion: '',
      CantidadFijaGC: '',
      Gastos_ejec: '',
      GastosEjecRequerimiento: '',
      GastosEjecEmbargo: '',
      GastosExtCobranza: '',
      Redondeo: '',
      Total: '1.00',
      municipio: '',
      listChecks: [],
      recalculate: false,
      isPayment: false,
      number: '',
      Name: '',
      Expires: '',
      cvc: '',
      issuer: '',
      focused: '',
      paymentLoading: false,
      opcion: 0
    };
    this.onChange = this.onChange.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCallback = this.handleCallback.bind(this);
  }

  componentDidMount(){
    this.findPredio();
  }

  onChange(event) {
    const { name, value } = event.target;
    const data = parseInt(value);

    this.setState({ [name]: value });

    if ( ! isNaN(data) && value.length === 3 ) {
      if ( name === 'mun' ) {
        this.frm['pob'].focus();

      }else if ( name === 'pob' ) {
        this.frm['cua'].focus();

      }else if ( name === 'cua' ) {
        this.frm['man'].focus();

      }else if ( name === 'man' ) {
        this.frm['col'].focus();

      }else if ( name === 'col' ) {
        this.frm['uni'].focus();
      }
    }
  }

  onChangePeriods({ item, checked }) {
    if ( ( item === 0 && ! checked ) || ( item > 0 && (checked || ! checked ) ) ) {
      const { listChecks } = this.state;

      listChecks.map((c, i) => {
        if ( checked || item === 0 ) {
          if ( i > item ) {
            c.checked = false;
          }else{
            c.checked = true;
          }
        }else{
          if ( i >= item ) {
            c.checked = false;
          }else{
            c.checked = true;
          }
        }
      });

      this.setState({ listChecks, recalculate: true });

      const last = this.findLastCheck(listChecks);

      this.handleRecalculate(last);
    }
  }

  findLastCheck(listChecks) {
    const selected = listChecks.filter(c => c.checked);
    return selected[selected.length-1];
  }

  findPredio() {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const c = params.get('clave');

    const mun = c.substring(0, 3);
    const pob = c.substring(3, 6);
    const cua = c.substring(6, 9);
    const man = c.substring(9, 12);
    const col = c.substring(12, 15);
    const uni = c.substring(15, 18);

    const clave = `${mun}-${pob}-${cua}-${man}-${col}-${uni}`;

    const body = { clave };

    if ( this.validateClave(body) ) {
      postRequest({
        url: 'search',
        body,
        success: ({ data, municipio }, status) => {
          if ( status === 201 ) {
            const listChecks = [];

            if ( typeof data.CalculaPUResult.Detalle !== 'undefined' ) {
              const { CalculaPUResult: { Detalle: { Detalle } } } = data;

              Detalle.map(d => {
                listChecks.push({
                  anio: d.anio,
                  trimestre: d.trimestre,
                  checked: true
                });
              });
            }

            window.scrollTo(300, 700);

            this.setState({
              data, mun, pob, cua, man, col, uni,
              predio: data,
              clave, municipio, listChecks,
              ImporteCruzRoja: data.CalculaPUResult.ImporteCruzRoja,
              Diferencia_predial: data.CalculaPUResult.Diferencia_predial,
              Honorarios: data.CalculaPUResult.Honorarios,
              Honorarios_Notificacion: data.CalculaPUResult.Honorarios_Notificacion,
              CantidadFijaGC: data.CalculaPUResult.CantidadFijaGC,
              Gastos_ejec: data.CalculaPUResult.Gastos_ejec,
              GastosEjecRequerimiento: data.CalculaPUResult.GastosEjecRequerimiento,
              GastosEjecEmbargo: data.CalculaPUResult.GastosEjecEmbargo,
              GastosExtCobranza: data.CalculaPUResult.GastosExtCobranza,
              Redondeo: data.CalculaPUResult.Redondeo,
              Total: data.CalculaPUResult.Total,
              isPayment: data.CalculaPUResult.utp === IS_PAY ? true : false,
              loadingProgress: false
            });
          }else{
            this.setState({ data: null, loadingProgress: false, serviceNotFound: true });
          }
        },
        fail: () => {
          this.setState({
            serviceNotFound: true,
            formCompleted: true,
            data: null,
            loadingProgress: false
          });
        }
      });
    }
  }

  handleRecalculate(last) {
    const { mun, pob, cua, man, col, uni } = this.state;

    const body = {
      clave: `${mun}-${pob}-${cua}-${man}-${col}-${uni}`,
      hasta: `${last.anio}${last.trimestre}`
    };

    postRequest({
      url: 'calculate',
      body,
      success: ({ data }, status) => {
        if ( status === 201 ) {
          this.setState({
            predio: data,
            Honorarios: data.CalculaPUResult.Honorarios,
            Honorarios_Notificacion: data.CalculaPUResult.Honorarios_Notificacion,
            GastosEjecRequerimiento: data.CalculaPUResult.GastosEjecRequerimiento,
            GastosEjecEmbargo: data.CalculaPUResult.GastosEjecEmbargo,
            GastosExtCobranza: data.CalculaPUResult.GastosExtCobranza,
            ImporteCruzRoja: data.CalculaPUResult.ImporteCruzRoja,
            Total: data.CalculaPUResult.Total,
            recalculate: false
          });
        }
      },
      fail: (error, status, data) => {
        console.log(error, status, data);
      }
    });
  }

  renderInfoClave() {
    const { recalculate, data, predio, opcion } = this.state;

    return (
      <Fragment>
        <section className="cart-section">
          <div className="consult-form">
            <InformationClave {...this.state} />

            {
              typeof data.CalculaPUResult.Detalle !== 'undefined' ?
                <DetailImports {...this.state} onChangePeriods={this.onChangePeriods.bind(this)} /> : null
            }

            { recalculate ? <LoadingProgress message="recalculando..." /> : null }

            <br />

            <SummaryImports {...this.state} />

            <div className="consult-form">
              <div className="row">
                <div className="form-group col-lg-4 col-md-4 col-sm-4"></div>
                <div className="form-group col-lg-2 col-md-2 col-sm-2">
                  <PreelaboradoPDF onClickCallback={this.handleSelectPreelaborado.bind(this)} predio={predio} />
                </div>

                <div className="form-group col-lg-2 col-md-2 col-sm-2">
                  <button onClick={this.handleSelectLinea.bind(this)} className="btn btn-info pull-right" type="button">
                    <Icon icon="file" /> Pagar en línea
                  </button>
                </div>
                <div className="form-group col-lg-4 col-md-4 col-sm-4"></div>
              </div>
            </div>
          </div>
        </section>

        {
          opcion === OPCION_PAGO_LINEA ?
            <section>
              <div className="auto-container">
                <CreditCard {...this.state}
                  handleCallback={this.handleCallback}
                  handleInputChange={this.handleInputChange}
                  handleInputFocus={this.handleInputFocus} />
              </div>
            </section> : null
        }
        <br />
      </Fragment>
    );
  }

  handleSelectLinea() {
    this.setState({ opcion: OPCION_PAGO_LINEA });
  }

  handleSelectPreelaborado() {
    this.setState({ opcion: OPCION_PREELABORADO });
  }

  renderClavePay() {
    return (
      <section className="cart-section">
        <AlertPaid type="danger" title="Aviso" text="La Clave Catastral es incorrecta" />
      </section>
    );
  }

  renderResultSearch() {
    const { isPayment } = this.state;

    return isPayment ? this.renderClavePay() : this.renderInfoClave();
  }

  render() {
    const { data, serviceNotFound, loadingProgress } = this.state;

    return (
      <Fragment>
        <div>
          <div className="auto-container">
            <Link to="/">Regresar</Link>
          </div>
        </div>

        { loadingProgress ? <LoadingSpin /> : null }

        { data !== null ? this.renderResultSearch() : null }

        { serviceNotFound ?
          <div className="auto-container">
            <br />
            <AlertDialog title="Aviso" text="El servicio no se encuentra disponible por el momento" />
            <br />
          </div>
          : null }
      </Fragment>
    );
  }

  validateClave(data) {
    let complete = true;

    const { clave } = data;

    if ( isEmpty(clave  + '') ) {
      complete = false;

      this.setState({
        data: null,
        formClass: 'needs-validation was-validated',
        loadingProgress: false
      });
    }

    const clave1 = clave.replace(/_/g, '');
    const clave2 = clave1.split('-');

    const fieldInvalid = clave2.reduce((t, f) => {
      if ( isNaN(parseInt(f)) ) {
        t++;
      }
      return t;
    }, 0);

    if ( complete  && ( clave2.length !== 6 || fieldInvalid > 0 ) )  {
      complete = false;

      this.setState({
        data: null,
        formClass: 'needs-validation was-validated',
        formCompleted: false,
        loadingProgress: false
      });
    }

    return complete;
  }

  handleCallback({ issuer }, isValid) {
    if ( isValid ) {
      this.setState({ issuer });
    }else{
      this.setState({ issuer: '' });
    }
  }

  handleInputFocus({ target }) {
    this.setState({
      focused: target.name,
    });
  }

  handleInputChange({ target }) {
    if (target.name === 'number') {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === 'Expires') {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === 'cvc') {
      target.value = formatCVC(target.value);
    }

    this.setState({ [target.name]: target.value });
  }

}

ConsultaPredio.propTypes = {
  location: PropTypes.object.isRequired
};
