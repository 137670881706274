import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ItemLabelTable extends Component {

  constructor(props){
    super(props);
  }

  render() {
    const { label, value } = this.props;

    return (
      <li className="clearfix">
        <span className="col col-title">{label}</span>
        <span className="col">{value}</span>
      </li>
    );
  }

}

ItemLabelTable.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired
};
