import React, { Component } from 'react';

export default class AlertPaid extends Component {

  constructor(props){
    super(props);
  }

  render() {
    return (
      <div className="auto-container">
        <div className="broucher-box">
          <div className="broucher-inner">
            <div className="content">
              <div className="icon">
                <img src="/img/aviso5.png" alt="" title="" width="40px" />
              </div>
              <h3>Información</h3>
              <div className="file">La clave se encuentra al corriete en sus pagos.</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}
