import React, { Component } from 'react';

export default class Footer extends Component {

  constructor(props){
    super(props);
  }

  render() {
    return (
      <footer className="main-footer">
        <div className="auto-container">
          <div className="widgets-section">
            <div className="row clearfix">
              <div className="big-column col-lg-6 col-md-12 col-sm-12">
                <div className="row clearfix">
                  <div className="footer-column col-lg-6 col-md-6 col-sm-12 col-6">
                    <div className="footer-widget about-widget">
                      <div className="logo-outer">
                        <div className="logo">
                          <a href="/"><img src="/img/ELOTA.png" alt="" title="" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="footer-column col-md-6 col-sm-12">
                    <div className="footer-widget links-widget">
                      <h2>Dirección:</h2>
                      <ul className="footer-link-list">
                        <li>Ave. Gabriel Leyva y Lic. Saúl Aguilar Pico,</li>
                        <li>C.P. 82700</li>
                        <li>La Cruz, Elota, Sinaloa</li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>

              <div className="big-column col-lg-6 col-md-12 col-sm-12">
                <div className="row clearfix">

                  <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                    <div className="footer-widget links-widget">
                      <h2>Números Telefónicos</h2>
                      <ul className="footer-link-list">
                        <li>01 696 96 1 01 11 </li>
                        <li>01 696 96 1 02 42</li>
                      </ul>
                    </div>
                  </div>

                  <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                    <div className="footer-widget services-widget">
                      <h2>Escríbenos a</h2>
                      <ul className="service-list">
                        <li>ingresos@elota.gob.mx</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom">
            <div className="clearfix">
              <div className="pull-left">
                <div className="copyright"><span>H. Ayuntamiento de Elota 2021 - 2024</span></div>
              </div>
              <div className="pull-right">
                <ul className="footer-list">
                  <li><a href="http://elota.gob.mx/cms/informacion-historica" target="_blank" rel="noopener noreferrer">Aviso de Privacidad</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }

}
